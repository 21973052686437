import {
  TEAM_MEMBER_SET_TEAMS,
  TEAM_MEMBER_GET_TEAMS,
  TEAM_MEMBER_TEAMS_SUCCESS,
  TEAM_MEMBER_SET_TEAMS_REQUEST,
  TEAM_MEMBER_TEAMS_ERROR,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

export const setTeamMembers = (teamMembers) => ({
  type: TEAM_MEMBER_SET_TEAMS,
  payload: { teamMembers },
});

export const getTeamMembers = (classId = '') => ({
  type: TEAM_MEMBER_GET_TEAMS,
  payload: { classId },
});

export const setTeamMembersSuccess = () => ({
  type: TEAM_MEMBER_TEAMS_SUCCESS,
});

export const setTeamMembersRequest = () => ({
  type: TEAM_MEMBER_SET_TEAMS_REQUEST,
});

export const setTeamMembersError = (message) => ({
  type: TEAM_MEMBER_TEAMS_ERROR,
  payload: { message },
});

const getTeamMembersAsync = async (classId) => {
  return await firestoreRef
    .collection('team_members')
    .where('class_id', '==', classId)
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });
};

function* fetchTeamMembers({ payload: { classId } }) {
  try {
    yield put(setTeamMembersRequest());
    const classes = yield call(getTeamMembersAsync, classId);
    yield put(setTeamMembers(classes));
    yield put(setTeamMembersSuccess());
  } catch (error) {
    yield put(setTeamMembersError(error.message));
  }
}

export function* watchGetTeamMembers() {
  yield takeEvery(TEAM_MEMBER_GET_TEAMS, fetchTeamMembers);
}

export default function* rootSaga() {
  yield all([fork(watchGetTeamMembers)]);
}
