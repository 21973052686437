import {
  TEAM_SET_TEAMS,
  TEAM_GET_TEAMS,
  TEAM_SET_TEAMS_SUCCESS,
  TEAM_SET_TEAMS_REQUEST,
  TEAM_SET_TEAMS_ERROR,
  TEAM_SET_TEAM_BY_ID,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

export const setTeams = (teams) => ({
  type: TEAM_SET_TEAMS,
  payload: { teams },
});

export const setTeamById = (teamId) => ({
  type: TEAM_SET_TEAM_BY_ID,
  payload: { teamId },
});

export const getTeams = (classId = '') => ({
  type: TEAM_GET_TEAMS,
  payload: { classId },
});

export const setTeamsSuccess = () => ({
  type: TEAM_SET_TEAMS_SUCCESS,
});

export const setTeamsRequest = () => ({
  type: TEAM_SET_TEAMS_REQUEST,
});

export const setTeamsError = (message) => ({
  type: TEAM_SET_TEAMS_ERROR,
  payload: { message },
});

const getTeamsAsync = async (classId) => {
  let ref = firestoreRef.collection('teams');
  if (classId) ref = ref.where('class_id', '==', classId);
  return await ref.get().then((snapshots) => {
    let data = [];
    snapshots.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    return data;
  });
};

function* fetchTeams({ payload: { classId } }) {
  try {
    // const { cls } = yield select(state => state.class);
    yield put(setTeamsRequest());
    const teams = yield call(getTeamsAsync, classId);
    yield put(setTeams(teams));
    yield put(setTeamsSuccess());
  } catch (error) {
    yield put(setTeamsError(error.message));
  }
}

export function* watchGetTeams() {
  yield takeEvery(TEAM_GET_TEAMS, fetchTeams);
}

export default function* rootSaga() {
  yield all([fork(watchGetTeams)]);
}
