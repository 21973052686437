import {
  CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS,
  CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM,
  CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

export const setCanvasTeamSubmits = (canvasTeamSubmits) => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS,
  payload: { canvasTeamSubmits },
});

export const setCanvasTeamSubmit = (canvasTeamSubmit) => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM,
  payload: { canvasTeamSubmit },
});

export const setCanvasTeamSubmitById = (canvasTeamSubmitId) => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID,
  payload: { canvasTeamSubmitId },
});

export const getCanvasTeamSubmits = (classId = '') => ({
  type: CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS,
  payload: { classId },
});

export const updateCanvasTeamSubmit = (data) => ({
  type: CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
  payload: { data },
});

export const setCanvasTeamSubmitsSuccess = () => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS,
});

export const setCanvasTeamSubmitsRequest = () => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST,
});

export const setCanvasTeamSubmitsError = (message) => ({
  type: CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR,
  payload: { message },
});

const getCanvasTeamSubmitsAsync = async (classId) => {
  return await firestoreRef
    .collection('canvas_team_submits')
    .where('class_id', '==', classId)
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });
};

export const getCanvasTeamSubmitAsync = async (classId, teamId, canvasId) => {
  return await firestoreRef
    .collection('canvas_team_submits')
    .where('class_id', '==', classId)
    .where('team_id', '==', teamId)
    .where('canvas_id', '==', canvasId)
    .get()
    .then((snapshots) => {
      if (snapshots.docs.length) return snapshots.docs[0].data();
      return {};
    });
};

const updateCanvasTeamSubmitAsync = async (data) => {
  return await firestoreRef
    .collection('canvas_team_submits')
    .where('class_id', '==', data.classId)
    .where('canvas_id', '==', data.canvasId)
    .where('team_id', '==', data.teamId)
    .get()
    .then((snapshots) => {
      if (_.isEqual(snapshots.size, 0)) return null;

      snapshots.docs[0].ref.update({
        comment: data.comment,
        status: data.status,
      });

      // snapshots.forEach((doc) => {
      //   firestoreRef.collection('canvas_team_submits').doc(doc.id).update({
      //     comment: value.comment,
      //     status: value.status,
      //   });
      // });

      return true;
    });
};

function* fetchCanvasSubmits({ payload: { classId } }) {
  try {
    // const { cls } = yield select((state) => state.class);
    yield put(setCanvasTeamSubmitsRequest());
    const canvasTeamSubmits = yield call(getCanvasTeamSubmitsAsync, classId);
    yield put(setCanvasTeamSubmits(canvasTeamSubmits));
    yield put(setCanvasTeamSubmitsSuccess());
  } catch (error) {
    yield put(setCanvasTeamSubmitsError(error.message));
  }
}

function* performUpdateCanvasTeamSubmit({ payload }) {
  try {
    const { data } = payload;
    // const { cls } = yield select((state) => state.class);
    // const { team } = yield select((state) => state.team);
    // const { canvas } = yield select((state) => state.canvas);

    yield put(setCanvasTeamSubmitsRequest());
    yield call(updateCanvasTeamSubmitAsync, data);
    yield put(setCanvasTeamSubmitsSuccess());
  } catch (error) {
    yield put(setCanvasTeamSubmitsError(error.message));
  }
}

export function* watchGetCanvasTeamSubmits() {
  yield takeEvery(CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS, fetchCanvasSubmits);
}

export function* watchPerformUpdateCanvasTeamSubmit() {
  yield takeEvery(
    CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM,
    performUpdateCanvasTeamSubmit
  );
}

export default function* rootSaga() {
  yield all([
    fork(watchGetCanvasTeamSubmits),
    fork(watchPerformUpdateCanvasTeamSubmit),
  ]);
}
