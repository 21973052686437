import {
  USER_GET_USERS,
  USER_SET_USERS,
  USER_SET_USERS_SUCCESS,
  USER_SET_USERS_REQUEST,
  USER_SET_USERS_ERROR,
  USER_SET_DELETE_UPDATE_USERS,
} from './actionType';
import { firestoreRef, firebaseApp, FIRSTORE_TIMESTAMP } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

export const setUsers = (users) => ({
  type: USER_SET_USERS,
  payload: { users },
});

export const getUsers = (schoolId = undefined, classId = undefined) => ({
  type: USER_GET_USERS,
  payload: { schoolId, classId },
});

export const deleteUpdateUsers = (user) => ({
  type: USER_SET_DELETE_UPDATE_USERS,
  payload: { user },
});

export const setUsersSuccess = () => ({
  type: USER_SET_USERS_SUCCESS,
});

export const setUsersRequest = () => ({
  type: USER_SET_USERS_REQUEST,
});

export const setUsersError = (message) => ({
  type: USER_SET_USERS_ERROR,
  payload: { message },
});

export const getUsersAsync = async (schoolId, classId) => {
  let ref = firestoreRef.collection('users');
  if (schoolId) ref = ref.where('school', '==', schoolId);
  if (classId) ref = ref.where('class_id', '==', classId);

  return await ref
    .orderBy('created', 'desc')
    // .where("class_id", "==", cls.id)
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
          is_old_team_leader: doc.data().is_team_leader,
        });
      });
      return data;
    });
};

const updateDeleteUsersAsync = async (obj) => {
  let {
    user: {
      id,
      school,
      class_id,
      is_remove,
      team_id,
      is_team_leader,
      has_change,
      is_old_team_leader,
    },
  } = obj;

  if (!has_change) return null;

  // remove team member
  if (is_remove) {
    await firestoreRef.collection('users').doc(id).update({
      class_id: firebaseApp.firestore.FieldValue.delete(),
      class_code: firebaseApp.firestore.FieldValue.delete(),
      team_id: firebaseApp.firestore.FieldValue.delete(),
      is_join_class: false,
      is_join_team: false,
      is_team_leader: false,
    });

    await firestoreRef
      .collection('team_members')
      .where('user_id', '==', id)
      .get()
      .then((snapshots) => {
        if (_.eq(snapshots.size, 0)) return null;

        snapshots.forEach((doc) => {
          firestoreRef.collection('team_members').doc(doc.id).delete();
        });
      });

    await firestoreRef
      .collection('enrollments')
      .where('user_id', '==', id)
      .get()
      .then((snapshots) => {
        if (_.eq(snapshots.size, 0)) return null;
        snapshots.forEach((doc) => {
          firestoreRef.collection('enrollments').doc(doc.id).delete();
        });
      });

    return null;
  }

  let updateDate = { is_team_leader: is_team_leader };
  if (is_old_team_leader === false && is_team_leader === true) {
    updateDate.upgrade_to_leader = true;
  }
  await firestoreRef.collection('users').doc(id).update(updateDate);

  if (team_id)
    firestoreRef.collection('users').doc(id).update({
      team_id: team_id,
    });

  await firestoreRef
    .collection('team_members')
    .where('user_id', '==', id)
    .get()
    .then((snapshots) => {
      if (_.eq(snapshots.size, 0)) return null;

      snapshots.forEach((doc) => {
        firestoreRef.collection('team_members').doc(doc.id).delete();
      });
    });

  if (team_id)
    await firestoreRef.collection('team_members').doc().set({
      class_id: class_id,
      created_at: FIRSTORE_TIMESTAMP,
      is_team_leader: is_team_leader,
      is_verify: true,
      route_url: '',
      school_id: school,
      team_id: team_id,
      user_id: id,
    });

  return null;
};

function* fetchUsers({ payload: { schoolId, classId } }) {
  try {
    yield put(setUsersRequest());
    const users = yield call(getUsersAsync, schoolId, classId);
    yield put(setUsers(users));
    yield put(setUsersSuccess());
  } catch (error) {
    yield put(setUsersError(error.message));
  }
}

function* performUpdateDeleteUsers({ payload }) {
  try {
    const { user } = payload;
    // yield put(setUsersRequest());
    yield call(updateDeleteUsersAsync, { user });
    // yield put(setUsersSuccess());
  } catch (error) {
    yield put(setUsersError(error.message));
  }
}

export function* watchGetUsers() {
  yield takeEvery(USER_GET_USERS, fetchUsers);
}

export function* watchPerformUpdateDeleteUsers() {
  yield takeEvery(USER_SET_DELETE_UPDATE_USERS, performUpdateDeleteUsers);
}

export default function* rootSaga() {
  yield all([fork(watchGetUsers), fork(watchPerformUpdateDeleteUsers)]);
}
