import React, { Component } from "react";
import PropTypes from "prop-types";

class Title extends Component {

  render() {
    const { tag: Tag, className, title } = this.props;
    return (
      <Tag className={className}>
        {title}
      </Tag>
    );
  }
}

Title.propTypes = {
  tag: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
};

Title.defaultProps = {
  tag: "h5",
};

export default Title;