// Libs
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

// Components
import Header from "./header";
import Body from "./body";

class Layout extends Component {

  render() {
    const { children, ...props } = this.props;
    return (
      <Fragment>
        <Header {...props}/>
        <Body>
          {children}
        </Body>
      </Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;