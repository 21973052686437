import {
  CANVAS_TEAM_GET_CANVAS_TEAMS,
  CANVAS_TEAM_SET_CANVAS_TEAMS,
  CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS,
  CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST,
  CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

export const setCanvasTeams = (canvasTeams) => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS,
  payload: { canvasTeams },
});

export const getCanvasTeams = (teamId, canvasId) => ({
  type: CANVAS_TEAM_GET_CANVAS_TEAMS,
  payload: { teamId, canvasId },
});

export const setCanvasTeamsSuccess = () => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS,
});

export const setCanvasTeamsRequest = () => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST,
});

export const setCanvasTeamsError = (message) => ({
  type: CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR,
  payload: { message },
});

const getCanvasTeamsAsync = async (teamId, canvasId) => {
  return await firestoreRef
    .collection('canvas_teams')
    .where('team_id', '==', teamId)
    .where('canvas_id', '==', canvasId)
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });
};

function* fetchCanvasTeams({ payload: { teamId, canvasId } }) {
  try {
    // const { team } = yield select((state) => state.team);
    // const { canvas } = yield select((state) => state.canvas);
    yield put(setCanvasTeamsRequest());
    const teams = yield call(getCanvasTeamsAsync, teamId, canvasId);
    yield put(setCanvasTeams(teams));
    yield put(setCanvasTeamsSuccess());
  } catch (error) {
    yield put(setCanvasTeamsError(error.message));
  }
}

export function* watchGetCanvasTeams() {
  yield takeEvery(CANVAS_TEAM_GET_CANVAS_TEAMS, fetchCanvasTeams);
}

export default function* rootSaga() {
  yield all([fork(watchGetCanvasTeams)]);
}
