// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import WrapperBody from "./wrapper-body";

class Body extends Component {

  render() {
    const { children } = this.props;
    return (
      <WrapperBody className="wrapper-body">
        {children}
      </WrapperBody>
    );
  }
}

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;