/* eslint-disable indent */
import _ from "lodash";
import {
  SCHOOL_SET_REQUEST,
  SCHOOL_SET_SUCCESS,
  SCHOOL_SET_ERROR,
  SCHOOL_SET_SCHOOL,
  SCHOOL_SET_SCHOOLS,
  SCHOOL_SET_SCHOOL_BY_ID
} from "../actions/actionType";

const INIT_STATE = {
  schools: [],
  school: {},
  loading: false,
  error: ""
};

const findById = (state, action) => {
  let found = _.find(state.schools, { id: action.payload.schoolId });

  return { ...state, school: found ? found : {} };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SCHOOL_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case SCHOOL_SET_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case SCHOOL_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case SCHOOL_SET_SCHOOLS:
      return { ...state, schools: action.payload.schools };
    case SCHOOL_SET_SCHOOL:
      return { ...state, school: action.payload.school };
    case SCHOOL_SET_SCHOOL_BY_ID:
      return findById(state, action);
    default:
      return { ...state };
  }
};
