import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  UPDATE_PASSWORD,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS
} from "../actions/actionType";
import { authRef } from "@/lib/firebase";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SING_IN } from "@/constants/routes";
import { getUserRoleAsync } from "./userRole";

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});
export const loginUserSuccess = user => ({
  type: LOGIN_USER_SUCCESS,
  payload: user
});
export const updatePassword = (password, history) => ({
  type: UPDATE_PASSWORD,
  payload: { password, history }
});
export const updateUserError = message => ({
  type: UPDATE_USER_ERROR,
  payload: { message }
});
export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS
});
export const loginUserError = message => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
});
export const registerUserSuccess = user => ({
  type: REGISTER_USER_SUCCESS,
  payload: user
});
export const registerUserError = message => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
});

export const logoutUser = history => ({
  type: LOGOUT_USER,
  payload: { history }
});

const loginWithEmailPasswordAsync = async (email, password) =>
  await authRef
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      const userRoles = yield call(getUserRoleAsync, loginUser.user.uid);
      if (!userRoles.length) {
        yield put(logoutAsync);
        yield put(loginUserError("Account not existed"));
        return false;
      } else {
        localStorage.setItem("user_id", loginUser.user.uid);
        localStorage.setItem("Authorize", true);
        yield put(loginUserSuccess(loginUser.user));
        history.push("/");
      }
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export const registerWithEmailPasswordAsync = async (email, password) =>
  await authRef
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const updatePasswordAsync = async password => {
  return await authRef.currentUser.updatePassword(password);
};

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      localStorage.setItem("user_id", registerUser.user.uid);
      yield put(registerUserSuccess(registerUser));
      history.push("/");
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

const logoutAsync = async () => {
  await authRef
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);
};

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield call(logoutAsync);
    localStorage.removeItem("user_id");
    localStorage.removeItem("Authorize");
    history.push(SING_IN);
  } catch (error) {
    console.log(error);
  }
}

function* doUpdatePassword({ payload }) {
  const { password } = payload;
  try {
    yield call(updatePasswordAsync, password);
    yield call(logoutAsync);
    localStorage.removeItem("user_id");
    localStorage.removeItem("Authorize");
    yield put(updateUserSuccess());
  } catch (error) {
    yield put(updateUserError(error.message));
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, doUpdatePassword);
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchUpdatePassword)
  ]);
}
