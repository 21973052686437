import styled from "styled-components";
import { PEOPLES_EXPLAINING } from "@/constants/images";

export const WrapperBodyLeft = styled.div`
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 75% 100%;
  background-image: url(${PEOPLES_EXPLAINING});
`;
