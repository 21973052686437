/* eslint-disable indent */
import {
  USER_ROLE_SET_REQUEST,
  USER_ROLE_SET_SUCCESS,
  USER_ROLE_SET_ERROR,
  USER_ROLE_SET_USER_ROLES,
  USER_ROLE_SET_USER_ROLE
} from "../actions/actionType";

const INIT_STATE = {
  userRoles: [],
  userRole: {},
  loading: false,
  error: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_ROLE_SET_REQUEST:
      return {
        ...state,
        loading: true,
        error: ""
      };
    case USER_ROLE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ""
      };
    case USER_ROLE_SET_ERROR:
      return { ...state, loading: false, error: action.payload.message };
    case USER_ROLE_SET_USER_ROLES:
      return { ...state, userRoles: action.payload.userRoles };
    case USER_ROLE_SET_USER_ROLE:
      return { ...state, userRole: action.payload.userRole };
    default:
      return { ...state };
  }
};
