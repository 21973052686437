import {
  TEACHER_CLASS_SET_TEACHER_CLASSES,
  TEACHER_CLASS_GET_TEACHER_CLASSES,
  TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS,
  TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST,
  TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR
} from "./actionType";
import { firestoreRef } from "@/lib/firebase";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

export const setTeacherClasses = teacherClasses => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES,
  payload: { teacherClasses }
});

export const getTeacherClasses = () => ({
  type: TEACHER_CLASS_GET_TEACHER_CLASSES
});

export const setTeacherClassesSuccess = () => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS
});

export const setTeacherClassesRequest = () => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST
});

export const setTeacherClassesError = message => ({
  type: TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR,
  payload: { message }
});

const getTeacherClassesAsync = async () => {
  return await firestoreRef
    .collection("teacher_classes")
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });
};

function* fetchTeacherClasses() {
  try {
    yield put(setTeacherClassesRequest());
    const teacherClasses = yield call(getTeacherClassesAsync);
    yield put(setTeacherClasses(teacherClasses));
    yield put(setTeacherClassesSuccess());
  } catch (error) {
    yield put(setTeacherClassesError(error.message));
  }
}

export function* watchGetTeacherClasses() {
  yield takeEvery(TEACHER_CLASS_GET_TEACHER_CLASSES, fetchTeacherClasses);
}

export default function* rootSaga() {
  yield all([fork(watchGetTeacherClasses)]);
}
