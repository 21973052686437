export const CLASS = "/class";
export const CLASS_PROGRESS = `${CLASS}/progress`;
export const CLASS_CANVASES = `${CLASS}/canvases`;
export const CLASS_CANVASES_DETAIL = `${CLASS}/canvases/canvases-detail`;
export const CLASS_SCORE = `${CLASS}score`;
export const CLASS_CLASS_DETAIL = `${CLASS}/class-detail`;
export const CLASS_CANVASES_BOX = `${CLASS}/canvases/canvases-box`;
export const CLASS_TEAM_DETAIL = `${CLASS}/team/team-detail`;
export const FORGET_PASSWORD = "/forget-password";
export const SING_IN = "/sign-in";
export const ACCOUNT_SETTING = "/account-setting";
export const NOT_FOUND_404 = "/404";
export const UNAUTHORIZED_401 = "/401";

export const TEAM = "/team";
export const SCORE = "/score";
export const STUDENT = "/student";
export const PROGRESS = "/progress";
export const CANVASES = "/canvases";
export const TEAM_TEAM_DETAIL = "/team/team-detail";
export const CLASS_DETAIL = "/class-detail";
