import { SubmissionError } from "redux-form";

export const isValidEmail = email => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const isValidYearRange = value => {
  return !/\b(20|[2-9][0-9])\d{2}-(20|[2-9][0-9])\d{2}\b/i.test(value);
};

export const validate = (rules, values) => {
  var errors = {};
  var isError = false;

  for (var field in rules) {
    var rule = rules[field];
    var value = rule["value"];
    if (values != null) {
      value = values[field];
    }
    var type = rule["type"];

    if (
      rule["required"] == true &&
      (value == null || (typeof value == "string" && value.trim() == ""))
    ) {
      errors[field] = rule["message"] || "This field is required";
      isError = true;
    }

    if (
      rule["required"] == true &&
      rule.type == "array" &&
      (value == null || value.length == 0)
    ) {
      errors[field] = {
        _error: rule["message"] || "Please select at least 1 of these"
      };
      isError = true;
    }

    if (rule["existed"] === true) {
      errors[field] = rule["message"] || "This field value already token";
      isError = true;
    }

    if (rule.validation && rule.type == "array" && value && value.length > 0) {
      const validation = rule.validation(value);
      if (validation) {
        errors[field] = { _error: validation.message };
        isError = true;
      }
    }

    if (type == "password" && value != null && value.length < 6) {
      errors[field] =
        rule["message"] || "Password must be 6 characters or more";
      isError = true;
    }

    if (rule.hasOwnProperty("equalsTo") && value != null) {
      var equalsTo = rule["equalsTo"];
      if (equalsTo && values[equalsTo.name] != value) {
        const message =
          equalsTo.message || `${field} and ${equalsTo.name} do not match!`;
        errors[field] = message;
        errors[equalsTo.name] = message;
        isError = true;
      }
    }

    if (type == "email") {
      if (rule["required"] == true) {
        if (value != "" && value !== null && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        } else if (value == null && value == "") {
          errors[field] = rule["message"] || "This field is required";
          isError = true;
        } else if (rule["existed"] == true) {
          errors[field] = rule["message"] || "Email already existed";
          isError = true;
        }
      } else {
        if (value != null && value != "" && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        } else if (rule["existed"] == true) {
          errors[field] = rule["message"] || "Email already existed";
          isError = true;
        }
      }

      if (rule["not_existed"] === true) {
        errors[field] = rule["message"] || "There is no account email";
        isError = true;
      }
    }

    if (type == "year-range") {
      if (rule["required"] == true) {
        if (value != "" && value !== null && isValidYearRange(value)) {
          errors[field] = rule["message"] || "Invalid input format";
          isError = true;
        } else if (value == null && value == "") {
          errors[field] = rule["message"] || "This field is required";
          isError = true;
        }
      } else {
        if (value != null && value != "" && isValidYearRange(value)) {
          errors[field] = rule["message"] || "Invalid input format";
          isError = true;
        }
      }
    }
  }

  return isError ? errors : null;
};

export const createSubmitAction = (
  rules,
  action,
  beforeSubmit,
  shouldDispatch = true,
  beforeSubmissionError
) => (values, dispatch) => {
  let error = null;

  error = validate(rules, values);
  if (error != null) {
    if (beforeSubmissionError) {
      beforeSubmissionError(values);
    }
    throw new SubmissionError(error);
  } else {
    let newValues = values;
    if (beforeSubmit) {
      newValues = beforeSubmit(values);
    }
    if (!shouldDispatch) {
      return action(newValues);
    }
    return dispatch(action(newValues));
  }
};