import {
  CLASS_GET_CLASSES,
  CLASS_GET_CLASSES_SUCCESS,
  CLASS_GET_CLASSES_ERROR,
  CLASS_SET_CLASSES,
  CLASS_SET_CLASS,
  CLASS_SET_CLASS_BY_ID,
  CLASS_ADD_CLASS,
  CLASS_UPDATE_CLASS,
} from './actionType';
import { firestoreRef, FIRSTORE_TIMESTAMP } from '@/lib/firebase';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { updateTeacher } from './teacher';

export const setClasses = (classes) => ({
  type: CLASS_SET_CLASSES,
  payload: { classes },
});

export const setClass = (cls) => ({
  type: CLASS_SET_CLASS,
  payload: { cls },
});

export const addClass = (cls) => ({
  type: CLASS_ADD_CLASS,
  payload: { cls },
});

export const updateClass = (cls) => ({
  type: CLASS_UPDATE_CLASS,
  payload: { cls },
});

export const setClassById = (classId) => ({
  type: CLASS_SET_CLASS_BY_ID,
  payload: { classId },
});

export const getClasses = (schoolId = undefined) => ({
  type: CLASS_GET_CLASSES,
  payload: { schoolId },
});

export const setClassesSuccess = () => ({
  type: CLASS_GET_CLASSES_SUCCESS,
});

export const setClassesRequest = () => ({
  type: CLASS_GET_CLASSES_SUCCESS,
});

export const setClassesError = (message) => ({
  type: CLASS_GET_CLASSES_ERROR,
  payload: { message },
});

const getClassesAsync = async (schoolId) => {
  let ref = firestoreRef.collection('classes');
  if (schoolId) ref = ref.where('school_id', '==', schoolId);

  return await ref
    .orderBy('created', 'desc')
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });
};

export const getClassesByTeacherAsync = async (teacherId) => {
  let ref = firestoreRef
    .collection('classes')
    .where('teacher_id', '==', teacherId);

  return await ref.get().then((snapshots) => {
    return snapshots.size;
  });
};

const addClassAsync = async (cls) =>
  await firestoreRef
    .collection('classes')
    .doc()
    .set({ ...cls, created: FIRSTORE_TIMESTAMP });

const updateClassAsync = async (cls) =>
  await firestoreRef
    .collection('classes')
    .doc(cls.id)
    .update({ ...cls });

function* addClassData({ payload }) {
  try {
    let { cls } = payload;
    const { school } = yield select((state) => state.school);
    cls = { ...cls, school_id: school.id };
    yield put(setClassesRequest());
    yield call(addClassAsync, cls);
    const totalClass = yield call(getClassesByTeacherAsync, cls.teacher_id);
    yield put(updateTeacher({ id: cls.teacher_id, total_class: totalClass }));
    yield put(setClassesSuccess());
  } catch (error) {
    yield put(setClassesError(error.message));
  }
}

function* updateClassData({ payload }) {
  try {
    let { cls } = payload;
    const { cls: oldClass } = yield select((state) => state.class);

    yield put(setClassesRequest());
    yield call(updateClassAsync, cls);

    let totalClass = yield call(getClassesByTeacherAsync, oldClass.teacher_id);
    yield put(
      updateTeacher({ id: oldClass.teacher_id, total_class: totalClass })
    );

    totalClass = yield call(getClassesByTeacherAsync, cls.teacher_id);
    yield put(updateTeacher({ id: cls.teacher_id, total_class: totalClass }));
    yield put(setClassesSuccess());
  } catch (error) {
    yield put(setClassesError(error.message));
  }
}

function* fetchClasses({ payload: { schoolId } }) {
  try {
    yield put(setClassesRequest());
    const classes = yield call(getClassesAsync, schoolId);
    yield put(setClasses(classes));
    yield put(setClassesSuccess());
  } catch (error) {
    yield put(setClassesError(error.message));
  }
}

export function* watchGetClasses() {
  yield takeEvery(CLASS_GET_CLASSES, fetchClasses);
}

export function* watchAddClass() {
  yield takeEvery(CLASS_ADD_CLASS, addClassData);
}

export function* watchUpdateClass() {
  yield takeEvery(CLASS_UPDATE_CLASS, updateClassData);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetClasses),
    fork(watchAddClass),
    fork(watchUpdateClass),
  ]);
}
