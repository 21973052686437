import {
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS,
  QUIZ_RESULT_LOG_GET_QUIZ_RESULT_LOGS,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST,
  QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

export const setQuizResultLogs = (quizResultLogs) => ({
  type: QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS,
  payload: { quizResultLogs },
});

export const getQuizResultLogs = (userIds = []) => ({
  type: QUIZ_RESULT_LOG_GET_QUIZ_RESULT_LOGS,
  payload: { userIds },
});

export const setQuizResultLogsSuccess = () => ({
  type: QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS,
});

export const setQuizResultLogsRequest = () => ({
  type: QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST,
});

export const setQuizResultLogsError = (message) => ({
  type: QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR,
  payload: { message },
});

const getQuizResultLogssAsync = async (userIds = []) => {
  const arrayData = _.chunk(userIds, 10).map((items) => {
    return firestoreRef
      .collection('quiz_result_logs')
      .where('user_id', 'in', items)
      .get()
      .then((snapshots) => {
        let data = [];
        snapshots.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        return data;
      });
  });

  const results = await Promise.all(arrayData);
  const flattenResults = _.flatten(results);
  return flattenResults;

  // await firestoreRef
  //   .collection('quiz_result_logs')
  //   .get()
  //   .then((snapshots) => {
  //     let data = [];
  //     snapshots.forEach((doc) => {
  //       data.push({
  //         ...doc.data(),
  //         id: doc.id,
  //       });
  //     });
  //     return data;
  //   });
};

function* fetchQuizResultLogs({ payload: { userIds } }) {
  try {
    yield put(setQuizResultLogsRequest());
    const classes = yield call(getQuizResultLogssAsync, userIds);
    yield put(setQuizResultLogs(classes));
  } catch (error) {
    yield put(setQuizResultLogsError(error.message));
  }
}

export function* watchGetQuizResultLogs() {
  yield takeEvery(QUIZ_RESULT_LOG_GET_QUIZ_RESULT_LOGS, fetchQuizResultLogs);
}

export default function* rootSaga() {
  yield all([fork(watchGetQuizResultLogs)]);
}
