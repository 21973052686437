/* eslint-disable indent */
import { REDUX_FORM_LOAD } from "../actions/actionType";

const INIT_STATE = {
  data: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REDUX_FORM_LOAD:
      return {
        data: action.payload.data
      };

    default:
      return { ...state };
  }
};
