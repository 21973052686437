import {
  SESSION_LOG_SET_SESSIONS,
  SESSION_LOG_GET_SESSIONS,
  SESSION_LOG_SET_SESSIONS_SUCCESS,
  SESSION_LOG_SET_SESSIONS_REQUEST,
  SESSION_LOG_SET_SESSIONS_ERROR,
} from './actionType';
import { firestoreRef } from '@/lib/firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';

export const setSessionLogs = (sessionLogs) => ({
  type: SESSION_LOG_SET_SESSIONS,
  payload: { sessionLogs },
});

export const getSessionLogs = (userIds = []) => ({
  type: SESSION_LOG_GET_SESSIONS,
  payload: { userIds },
});

export const setSessionLogsSuccess = () => ({
  type: SESSION_LOG_SET_SESSIONS_SUCCESS,
});

export const setSessionLogsRequest = () => ({
  type: SESSION_LOG_SET_SESSIONS_REQUEST,
});

export const setSessionLogsError = (message) => ({
  type: SESSION_LOG_SET_SESSIONS_ERROR,
  payload: { message },
});

const getSessionLogsAsync = async (userIds = []) => {
  const arrayData = _.chunk(userIds, 10).map((items) => {
    return firestoreRef
      .collection('session_logs')
      .where('user_id', 'in', items)
      .get()
      .then((snapshots) => {
        let data = [];
        snapshots.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
          });
        });

        return data;
      });
  });

  const results = await Promise.all(arrayData);
  const flattenResults = _.flatten(results);
  return flattenResults;

  // await firestoreRef
  //   .collection('session_logs')
  //   // .where('user_id', 'in', [])
  //   .get()
  //   .then((snapshots) => {
  //     let data = [];
  //     snapshots.forEach((doc) => {
  //       data.push({
  //         ...doc.data(),
  //         id: doc.id,
  //       });
  //     });

  //     return data;
  //   });
};
function* fetchSessionLogs({ payload: { userIds } }) {
  try {
    yield put(setSessionLogsRequest());
    const classes = yield call(getSessionLogsAsync, userIds);
    yield put(setSessionLogs(classes));
    yield put(setSessionLogsSuccess());
  } catch (error) {
    yield put(setSessionLogsError(error.message));
  }
}

export function* watchGetCanvases() {
  yield takeEvery(SESSION_LOG_GET_SESSIONS, fetchSessionLogs);
}

export default function* rootSaga() {
  yield all([fork(watchGetCanvases)]);
}
