// Libs
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Layout from "@/components/layout";
import NotFound from "@/components/not-found";
import AdminDashboard from "@/views/pages/admin-dashboard";
// import Fix from "@/Fix";

class Private extends Component {

  render() {
    return (
      <Layout {...this.props}>
        <Switch>
          {/* <Route
            path="/fix"
            component={Fix}
          /> */}
          <Route
            path="/"
            component={AdminDashboard}
          />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default Private;