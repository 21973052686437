// Libs
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import Layout from "@/components/layout";
import SignIn from "@/views/pages/sign-in";
import NotFound from "@/components/not-found";

class Public extends Component {

  render() {
    return (
      <Layout {...this.props}>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/sign-in"
          />
          <Route
            path="/sign-in"
            component={SignIn}
          />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default Public;