import { all } from "redux-saga/effects";
import authSaga from "@/stores/actions/auth";
import classSaga from "@/stores/actions/class";
import canvasSaga from "@/stores/actions/canvas";
import canvasTeamSaga from "@/stores/actions/canvasTeam";
import canvasTeamSubmitSaga from "@/stores/actions/canvasTeamSubmit";
import quizResultLogSaga from "@/stores/actions/quizResultLog";
import sessionSaga from "@/stores/actions/session";
import sessionLogSaga from "@/stores/actions/sessionLog";
import schoolSaga from "@/stores/actions/school";
import teamSaga from "@/stores/actions/team";
import teamMemberSaga from "@/stores/actions/teamMember";
import teacherClassSaga from "@/stores/actions/teacherClass";
import teacherSaga from "@/stores/actions/teacher";
import userSaga from "@/stores/actions/user";
import userRoleSaga from "@/stores/actions/userRole";

export default function* rootSaga() {
  yield all([
    authSaga(),
    classSaga(),
    canvasSaga(),
    canvasTeamSaga(),
    canvasTeamSubmitSaga(),
    quizResultLogSaga(),
    sessionSaga(),
    sessionLogSaga(),
    schoolSaga(),
    teamSaga(),
    teamMemberSaga(),
    teacherClassSaga(),
    teacherSaga(),
    userSaga(),
    userRoleSaga()
  ]);
}
