// Libs
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// Components
import Async from "@/components/async";
import NotFound from "@/components/not-found";

const AsyncAdminDashboardPage = Async({
  loader: () => import("./pages")
});

const AdminDashboard = ({ match: { path } }) => (
  <Switch>
    <Route
      path={path}
      component={AsyncAdminDashboardPage}
    />
    <Route component={NotFound} />
  </Switch>
);

AdminDashboard.propTypes = {
  match: PropTypes.any
};

export default AdminDashboard;