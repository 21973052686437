import { parse } from "query-string";
import moment from "moment";
// import { EditorState, ContentState, convertFromHTML } from "draft-js";
// import { ALT_MEDIA, CLOUD_STORAGE_PREFIX } from "@/constants/images";

export const visibleTab = (function() {
  var stateKey,
    eventKey,
    keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    };
  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey];
      break;
    }
  }
  return function(c) {
    if (c) document.addEventListener(eventKey, c);
    return !document[stateKey];
  };
})();

export const randomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

// export const setFullMediaPath = imagePath => {
//   return CLOUD_STORAGE_PREFIX + imagePath.replace("/", "%2F") + ALT_MEDIA;
// };

export const calculatePercentage = (total, number) => {
  return number || total ? Math.ceil((number / total) * 100) : 0;
};

export const getLocationPathname = (history, replacePath = "/canvases/") => {
  return history.location.pathname.replace(replacePath, "");
};

export const getLocationParamSearch = history => {
  return parse(history.location.search);
};

export const formatDateFirestore = (
  objDate,
  format = "DD/MM/YYYY HH:mm:ss"
) => {
  return moment(objDate.seconds * 1000).format(format);
};

export const findObject = (objects, id, compareId) => {
  let tmObj = objects.find(obj => {
    return obj[id] === compareId;
  });

  return tmObj;
};

export const findObjects = (objects, keys = [], compareIds = []) => {
  let tmObj = objects.find(obj => {
    let bool = false;
    keys.forEach((key, i) => {
      bool = obj[key] === compareIds[i];
    });
    return bool;
  });

  return tmObj;
};

export const getProperty = (data, field = "name") => {
  if (!data) return "";
  if (data[field]) return data[field];
  return "";
};

export const isEmpty = data => {
  if (typeof data === "object") {
    for (let key in data) if (data.hasOwnProperty(key)) return false;
  }
  return true;
};

export const getUserName = data => {
  return getProperty(data, "name");
};

export const getProfile = data => {
  return getProperty(data, "profile");
};

export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getCurrentYear = () => {
  const now = new Date();
  return now.getFullYear();
};

export const randomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};
