import {
  SCHOOL_SET_SCHOOLS,
  SCHOOL_GET_SCHOOL,
  SCHOOL_GET_SCHOOLS,
  SCHOOL_SET_SUCCESS,
  SCHOOL_SET_REQUEST,
  SCHOOL_SET_ERROR,
  SCHOOL_SET_SCHOOL,
  SCHOOL_ADD_SCHOOL,
  SCHOOL_SET_SCHOOL_BY_ID,
  SCHOOL_UPDATE_SCHOOL,
  SCHOOL_DELETE_SCHOOL,
} from './actionType';
import {
  firestoreRef,
  FIRSTORE_TIMESTAMP,
  generateDocKey,
} from '@/lib/firebase';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';

export const setSchools = (schools) => ({
  type: SCHOOL_SET_SCHOOLS,
  payload: { schools },
});

export const setSchool = (school) => ({
  type: SCHOOL_SET_SCHOOL,
  payload: { school },
});

export const setSchoolById = (schoolId) => ({
  type: SCHOOL_SET_SCHOOL_BY_ID,
  payload: { schoolId },
});

export const addSchool = (school) => ({
  type: SCHOOL_ADD_SCHOOL,
  payload: { school },
});

export const deleteSchool = (id) => ({
  type: SCHOOL_DELETE_SCHOOL,
  payload: { id },
});

export const updateSchool = (school) => ({
  type: SCHOOL_UPDATE_SCHOOL,
  payload: { school },
});

export const getSchools = () => ({
  type: SCHOOL_GET_SCHOOLS,
});

export const getSchool = () => ({
  type: SCHOOL_GET_SCHOOL,
});

export const setSchoolSuccess = () => ({
  type: SCHOOL_SET_SUCCESS,
});

export const setSchoolRequest = () => ({
  type: SCHOOL_SET_REQUEST,
});

export const setSchoolError = (message) => ({
  type: SCHOOL_SET_ERROR,
  payload: { message },
});

const getSchoolsAsync = async () =>
  await firestoreRef
    .collection('schools')
    .orderBy('created', 'desc')
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return data;
    });

export const updateTotalStudentAndTotalTeacher = async () => {
  // let schoolSnapshot = await firestoreRef
  //   .collection('schools')
  //   .doc('mWpxK1vHId7xkOfg78tO')
  //   .get();

  // if (!schoolSnapshot.exists) {
  //   console.log(22222);
  //   return false;
  // }

  // console.log(3);

  // let { total_student } = schoolSnapshot.data();
  // total_student = total_student || 0;
  // total_student += 1;

  // await schoolSnapshot.ref.update({ total_student });

  let schoolSnapshots = await firestoreRef
    .collection('schools')
    // .where('id', '==', 'mWpxK1vHId7xkOfg78tO')
    .get();

  schoolSnapshots.docs.forEach(async (doc) => {
    const totalStudent = await firestoreRef
      .collection('users')
      .where('school', '==', doc.id)
      .get()
      .then((snapshots) => snapshots.size);

    const totalTeacher = await firestoreRef
      .collection('teachers')
      .where('school_ids', 'array-contains-any', [doc.id])
      .get()
      .then((snapshots) => snapshots.size);

    doc.ref.update({
      total_student: totalStudent,
      total_teacher: totalTeacher,
    });
  });
};

// updateTotalStudentAndTotalTeacher();

const addSchoolsAsync = async (school) => {
  const id = generateDocKey('schools');
  return await firestoreRef
    .collection('schools')
    .doc(id)
    .set({ ...school, id, created: FIRSTORE_TIMESTAMP });
};

const updateSchoolsAsync = async (school) =>
  await firestoreRef
    .collection('schools')
    .doc(school.id)
    .update({ ...school, id: school.id });

const deleteSchoolsAsync = async (id) =>
  await firestoreRef.collection('schools').doc(id).delete();

const getSchoolAsync = async (obj) => {
  const {
    teacher: { school_id },
  } = obj;

  return await firestoreRef
    .collection('schools')
    .doc(school_id)
    .get()
    .then((doc) => {
      let data = { ...doc.data(), id: doc.id };
      return data;
    });
};

function* fetchSchools() {
  try {
    yield put(setSchoolRequest());
    const schools = yield call(getSchoolsAsync);
    yield put(setSchools(schools));
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

function* addSchoolData({ payload }) {
  try {
    const { school } = payload;
    yield put(setSchoolRequest());
    yield call(addSchoolsAsync, school);
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

function* updateSchoolData({ payload }) {
  try {
    const { school } = payload;
    yield put(setSchoolRequest());
    yield call(updateSchoolsAsync, school);
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

function* deleteSchoolData({ payload }) {
  try {
    const { id } = payload;
    yield put(setSchoolRequest());
    yield call(deleteSchoolsAsync, id);
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

function* fetchSchool() {
  try {
    const { teacher } = yield select((state) => state.teacher);
    yield put(setSchoolRequest());
    const school = yield call(getSchoolAsync, { teacher });
    yield put(setSchool(school));
    yield put(setSchoolSuccess());
  } catch (error) {
    yield put(setSchoolError(error.message));
  }
}

export function* watchGetSchools() {
  yield takeEvery(SCHOOL_GET_SCHOOLS, fetchSchools);
}

export function* watchGetSchool() {
  yield takeEvery(SCHOOL_GET_SCHOOL, fetchSchool);
}

export function* watchAddSchool() {
  yield takeEvery(SCHOOL_ADD_SCHOOL, addSchoolData);
}

export function* watchUpdateSchool() {
  yield takeEvery(SCHOOL_UPDATE_SCHOOL, updateSchoolData);
}

export function* watchDeleteSchool() {
  yield takeEvery(SCHOOL_DELETE_SCHOOL, deleteSchoolData);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetSchools),
    fork(watchGetSchool),
    fork(watchAddSchool),
    fork(watchUpdateSchool),
    fork(watchDeleteSchool),
  ]);
}
