// Libs
import React from "react";
import { Button } from "antd";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// Components
import Title from "@/components/title";
import InputText from "@/components/input";
import FormWrapper from "@/components/form";

// Util
import { history } from "@/lib/utils/history";

// Helper
import { createSubmitAction } from "@/lib";
import { loginUser } from "@/stores/actions";
import { WrapperBodyLeft } from "./styles";

function SignIn() {
  const { error } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleLogin = values => {
    dispatch(loginUser(values, history));
  };

  const onSubmit = (values, dispatch) => {
    const validationRule = {
      email: {
        required: true,
        type: "email"
      },
      password: {
        required: true
      }
    };
    // if (values.email !== "admin@admin.com") {
    //   validationRule.email.not_existed = true;
    // }
    return createSubmitAction(
      validationRule,
      handleLogin,
      null, // if no need custom values before submit pass null
      false // if function don't need dispatch pass false
    )(values, dispatch);
  };

  return (
    <Container fluid>
      <Row className="h-100">
        <Col md="6" xs="12">
          <WrapperBodyLeft />
        </Col>
        <Col
          md="6"
          xs="12"
          className="h-100 text-center bg-gradient-primary-left-right"
        >
          <Row className="h-100">
            <Col lg="8" md="10" xs="12" className="m-auto">
              <Title
                title={"Admin Log In"}
                isLocale={true}
                className="text-white mb-4"
              />
              {error && (
                <div className="p-3 mb-3 border-2 border-danger bg-danger text-center text-muted rounded">
                  <Title className="text-muted" title="Wrong Credentials" />
                  <span>Invalid username or password.</span>
                </div>
              )}
              <FormWrapper form="singIn" onSubmit={onSubmit}>
                <InputText name="email" placeholder="Email Address" />
                <InputText
                  name="password"
                  type="Password"
                  placeholder="Password"
                />
                <Button htmlType="submit" className="mt-3 px-4 text-primary">
                  Submit
                </Button>
                {/* <Label className="d-flex align-items-center justify-content-center text-white mt-3">
                  Already have an account?
                  <Link to="sign-up">
                    <span className="text-warning px-1">Sign Up</span>
                  </Link>
                  now.
                </Label> */}
              </FormWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;
