import {
  USER_ROLE_SET_REQUEST,
  USER_ROLE_SET_SUCCESS,
  USER_ROLE_SET_ERROR,
  USER_ROLE_SET_USER_ROLES,
  USER_ROLE_GET_USER_ROLES
} from "./actionType";
import { firestoreRef } from "@/lib/firebase";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

export const setUserRoles = userRoles => ({
  type: USER_ROLE_SET_USER_ROLES,
  payload: { userRoles }
});

export const getUserRoles = () => ({
  type: USER_ROLE_GET_USER_ROLES
});

export const setUserRoleSuccess = () => ({
  type: USER_ROLE_SET_SUCCESS
});

export const setUserRoleRequest = () => ({
  type: USER_ROLE_SET_REQUEST
});

export const setUserRoleError = message => ({
  type: USER_ROLE_SET_ERROR,
  payload: { message }
});

export const getUserRoleAsync = async userId =>
  await firestoreRef
    .collection("user_roles")
    .where("user_id", "==", userId)
    .where("role_id", "==", "admin")
    .get()
    .then(snapshots => {
      let data = [];
      snapshots.forEach(doc => {
        data.push({
          ...doc.data(),
          id: doc.id
        });
      });
      return data;
    });

function* fetchUserRoles() {
  try {
    yield put(setUserRoleRequest());
    const userRoles = yield call(getUserRoleAsync);
    yield put(setUserRoles(userRoles));
  } catch (error) {
    yield put(setUserRoleError(error.message));
  }
}

export function* watchGetUserRoles() {
  yield takeEvery(USER_ROLE_GET_USER_ROLES, fetchUserRoles);
}

export default function* rootSaga() {
  yield all([fork(watchGetUserRoles)]);
}
