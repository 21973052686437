import {
  TEACHER_SET_TEACHERS,
  TEACHER_GET_TEACHERS,
  TEACHER_GET_TEACHER,
  TEACHER_SET_SUCCESS,
  TEACHER_SET_REQUEST,
  TEACHER_SET_ERROR,
  TEACHER_SET_TEACHER,
  TEACHER_UPDATE_TEACHER,
  TEACHER_ADD_TEACHER,
} from './actionType';
import { firestoreRef, FIRSTORE_TIMESTAMP } from '@/lib/firebase';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { registerWithEmailPasswordAsync } from './auth';
import _ from 'lodash';
import { getClassesByTeacherAsync } from './class';

export const setTeachers = (teachers) => ({
  type: TEACHER_SET_TEACHERS,
  payload: { teachers },
});

export const setTeacher = (teacher) => ({
  type: TEACHER_SET_TEACHER,
  payload: { teacher },
});

export const addTeacher = (teacher, param) => ({
  type: TEACHER_ADD_TEACHER,
  payload: { teacher, param },
});

export const getTeachers = () => ({
  type: TEACHER_GET_TEACHERS,
});

export const getTeacher = (teacherId) => ({
  type: TEACHER_GET_TEACHER,
  payload: { teacherId },
});

export const updateTeacher = (teacher) => ({
  type: TEACHER_UPDATE_TEACHER,
  payload: { teacher },
});

export const setTeacherSuccess = () => ({
  type: TEACHER_SET_SUCCESS,
});

export const setTeacherRequest = () => ({
  type: TEACHER_SET_REQUEST,
});

export const setTeacherError = (message) => ({
  type: TEACHER_SET_ERROR,
  payload: { message },
});

const getTeachersAsync = async () =>
  await firestoreRef
    .collection('teachers')
    .orderBy('created', 'desc')
    .get()
    .then((snapshots) => {
      let data = [];
      snapshots.forEach((doc) => {
        data.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      return data;
    });

const updateTeacherAsync = async (data) =>
  await firestoreRef.collection('teachers').doc(data.id).update(data);

export const getTeacherAsync = async (teacherId) =>
  await firestoreRef
    .collection('teachers')
    .doc(teacherId)
    .get()
    .then((doc) => {
      let data = { ...doc.data(), id: doc.id };
      return data;
    });

const addTeacherAsync = async (teacher) =>
  await firestoreRef
    .collection('teachers')
    .doc(teacher.id)
    .set({ ...teacher, created: FIRSTORE_TIMESTAMP });

function* addTeacherData({ payload }) {
  const { teacher, param } = payload;
  try {
    yield put(setTeacherRequest());

    let user = yield call(
      registerWithEmailPasswordAsync,
      teacher.email,
      '12345678'
    );

    let userId = '';
    if (user.code === 'auth/email-already-in-use') {
      const { users } = yield select((state) => state.user);
      let found = _.find(users, { email: teacher.email });

      if (!found.id) {
        yield put(setTeacherError('No note in user!'));
        param.message();
        return false;
      }

      userId = found.id;
    } else userId = user.user.uid;

    yield call(addTeacherAsync, { ...teacher, id: userId });
    yield put(setTeacherSuccess());
    param.handleBack();
  } catch (error) {
    yield put(setTeacherError(error.message));
    param.message();
  }
}

function* fetchTeachers() {
  try {
    yield put(setTeacherRequest());
    const teachers = yield call(getTeachersAsync);
    yield put(setTeachers(teachers));
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

function* fetchTeacher({ payload }) {
  try {
    yield put(setTeacherRequest());
    const teacher = yield call(getTeacherAsync, payload.teacherId);
    yield put(setTeacher(teacher));
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

function* setUpdateTeacher({ payload }) {
  try {
    const { teacher: data } = payload;
    yield put(setTeacherRequest());
    const totalClass = yield call(getClassesByTeacherAsync, data.id);
    yield call(updateTeacherAsync, { ...data, total_class: totalClass });
    yield put(setTeacherSuccess());
  } catch (error) {
    yield put(setTeacherError(error.message));
  }
}

export function* watchGetTeachers() {
  yield takeEvery(TEACHER_GET_TEACHERS, fetchTeachers);
}

export function* watchGetTeacher() {
  yield takeEvery(TEACHER_GET_TEACHER, fetchTeacher);
}

export function* watchUpdateTeacher() {
  yield takeEvery(TEACHER_UPDATE_TEACHER, setUpdateTeacher);
}

export function* watchAddTeacher() {
  yield takeEvery(TEACHER_ADD_TEACHER, addTeacherData);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetTeachers),
    fork(watchGetTeacher),
    fork(watchUpdateTeacher),
    fork(watchAddTeacher),
  ]);
}
