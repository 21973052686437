// Libs
import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyleWrapperBody = styled.div`
  display: flex;
  min-height: calc(100% - 80px);
`;

class WrapperBody extends Component {

  render() {
    const {
      children,
      className,
    } = this.props;
    return (
      <StyleWrapperBody className={className}>
        {children}
      </StyleWrapperBody>
    );
  }
}

WrapperBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default WrapperBody;