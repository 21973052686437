export const SET_LOCALE = "SET_LOCALE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_TYPE = "LOGIN_CREDENTIAL";
export const CLIENT_CREDENTIAL_TYPE = "CLIENT_CREDENTIAL";
export const CLIENT_CREDENTIAL_REQUEST = "CLIENT_CREDENTIAL_REQUEST";

export const CHANGE_LOCALE = "CHANGE_LOCALE";

// Redux form load
export const REDUX_FORM_LOAD = "REDUX_FORM_LOAD";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

// Class
export const CLASS_SET_CLASSES = "CLASS_SET_CLASSES";
export const CLASS_GET_CLASSES = "CLASS_GET_CLASSES";
export const CLASS_GET_CLASSES_REQUEST = "CLASS_GET_CLASSES_REQUEST";
export const CLASS_GET_CLASSES_SUCCESS = "CLASS_GET_CLASSES_SUCCESS";
export const CLASS_GET_CLASSES_ERROR = "CLASS_GET_CLASSES_ERROR";
export const CLASS_SET_CLASS = "CLASS_SET_CLASS";
export const CLASS_SET_CLASS_BY_ID = "CLASS_SET_CLASS_BY_ID";
export const CLASS_ADD_CLASS = "CLASS_ADD_CLASS";
export const CLASS_UPDATE_CLASS = "CLASS_UPDATE_CLASS";

// Canvas
export const CANVAS_SET_CANVASES_REQUEST = "CANVAS_SET_CANVASES_REQUEST";
export const CANVAS_SET_CANVASES_SUCCESS = "CANVAS_SET_CANVASES_SUCCESS";
export const CANVAS_SET_CANVASES_ERROR = "CANVAS_SET_CANVASES_ERROR";
export const CANVAS_SET_CANVASES = "CANVAS_SET_CANVASES";
export const CANVAS_GET_CANVASES = "CANVAS_GET_CANVASES";
export const CANVAS_SET_CANVAS = "CANVAS_SET_CANVAS";
export const CANVAS_SET_CANVAS_BY_ID = "CANVAS_SET_CANVAS_BY_ID";

// CanvasTeam
export const CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST =
  "CANVAS_TEAM_SET_CANVAS_TEAMS_REQUEST";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS =
  "CANVAS_TEAM_SET_CANVAS_TEAMS_SUCCESS";
export const CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR =
  "CANVAS_TEAM_SET_CANVAS_TEAMS_ERROR";
export const CANVAS_TEAM_SET_CANVAS_TEAMS = "CANVAS_TEAM_SET_CANVAS_TEAMS";
export const CANVAS_TEAM_GET_CANVAS_TEAMS = "CANVAS_TEAM_GET_CANVAS_TEAMS";
export const CANVAS_TEAM_SET_CANVAS_TEAM = "CANVAS_TEAM_SET_CANVAS_TEAM";

// CanvasTeamSubmit
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_REQUEST";
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_SUCCESS";
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS_ERROR";
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAMS";
export const CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS =
  "CANVAS_TEAM_SUBMIT_GET_CANVAS_TEAMS";
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM";
export const CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM =
  "CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM";
export const CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM_BY_ID =
  "CANVAS_TEAM_SUBMIT_UPDATE_CANVAS_TEAM_BY_ID";
export const CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID =
  "CANVAS_TEAM_SUBMIT_SET_CANVAS_TEAM_BY_ID";

// QuizResultLog
export const QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST =
  "QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_REQUEST";
export const QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS =
  "QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_SUCCESS";
export const QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR =
  "QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS_ERROR";
export const QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS =
  "QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOGS";
export const QUIZ_RESULT_LOG_GET_QUIZ_RESULT_LOGS =
  "QUIZ_RESULT_LOG_GET_QUIZ_RESULT_LOGS";
export const QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOG =
  "QUIZ_RESULT_LOG_SET_QUIZ_RESULT_LOG";

// Session
export const SESSION_SET_SESSIONS_REQUEST = "SESSION_SET_SESSIONS_REQUEST";
export const SESSION_SET_SESSIONS_SUCCESS = "SESSION_SET_SESSIONS_SUCCESS";
export const SESSION_SET_SESSIONS_ERROR = "SESSION_SET_SESSIONS_ERROR";
export const SESSION_SET_SESSIONS = "SESSION_SET_SESSIONS";
export const SESSION_GET_SESSIONS = "SESSION_GET_SESSIONS";
export const SESSION_SET_SESSION = "SESSION_SET_SESSION";

// SessionLog
export const SESSION_LOG_SET_SESSIONS_REQUEST =
  "SESSION_LOG_SET_SESSIONS_REQUEST";
export const SESSION_LOG_SET_SESSIONS_SUCCESS =
  "SESSION_LOG_SET_SESSIONS_SUCCESS";
export const SESSION_LOG_SET_SESSIONS_ERROR = "SESSION_LOG_SET_SESSIONS_ERROR";
export const SESSION_LOG_SET_SESSIONS = "SESSION_LOG_SET_SESSIONS";
export const SESSION_LOG_GET_SESSIONS = "SESSION_LOG_GET_SESSIONS";
export const SESSION_LOG_SET_SESSION = "SESSION_LOG_SET_SESSION";

// Team
export const TEAM_SET_TEAMS_REQUEST = "TEAM_SET_TEAMS_REQUEST";
export const TEAM_SET_TEAMS_SUCCESS = "TEAM_SET_TEAMS_SUCCESS";
export const TEAM_SET_TEAMS_ERROR = "TEAM_SET_TEAMS_ERROR";
export const TEAM_SET_TEAMS = "TEAM_SET_TEAMS";
export const TEAM_GET_TEAMS = "TEAM_GET_TEAMS";
export const TEAM_SET_TEAM = "TEAM_SET_TEAM";
export const TEAM_SET_TEAM_BY_ID = "TEAM_SET_TEAM_BY_ID";

// TeamMember
export const TEAM_MEMBER_SET_TEAMS_REQUEST = "TEAM_MEMBER_SET_TEAMS_REQUEST";
export const TEAM_MEMBER_TEAMS_SUCCESS = "TEAM_MEMBER_TEAMS_SUCCESS";
export const TEAM_MEMBER_TEAMS_ERROR = "TEAM_MEMBER_TEAMS_ERROR";
export const TEAM_MEMBER_SET_TEAMS = "TEAM_MEMBER_SET_TEAMS";
export const TEAM_MEMBER_GET_TEAMS = "TEAM_MEMBER_GET_TEAMS";
export const TEAM_MEMBER_SET_TEAM = "TEAM_MEMBER_SET_TEAM";

// User
export const USER_SET_USERS_REQUEST = "USER_SET_USERS_REQUEST";
export const USER_SET_USERS_SUCCESS = "USER_SET_USERS_SUCCESS";
export const USER_SET_USERS_ERROR = "USER_SET_USERS_ERROR";
export const USER_SET_USERS = "USER_SET_USERS";
export const USER_GET_USERS = "USER_GET_USERS";
export const USER_SET_USER = "USER_SET_USER";
export const USER_SET_DELETE_UPDATE_USERS = "USER_SET_DELETE_UPDATE_USERS";

export const USER_ROLE_SET_REQUEST = "USER_ROLE_SET_REQUEST";
export const USER_ROLE_SET_SUCCESS = "USER_ROLE_SET_SUCCESS";
export const USER_ROLE_SET_ERROR = "USER_ROLE_SET_ERROR";
export const USER_ROLE_SET_USER_ROLES = "USER_ROLE_SET_USER_ROLES";
export const USER_ROLE_GET_USER_ROLES = "USER_ROLE_GET_USER_ROLES";
export const USER_ROLE_SET_USER_ROLE = "USER_ROLE_SET_USER_ROLE";

// TeacherClass
export const TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST =
  "TEACHER_CLASS_SET_TEACHER_CLASSES_REQUEST";
export const TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS =
  "TEACHER_CLASS_SET_TEACHER_CLASSES_SUCCESS";
export const TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR =
  "TEACHER_CLASS_SET_TEACHER_CLASSES_ERROR";
export const TEACHER_CLASS_SET_TEACHER_CLASSES =
  "TEACHER_CLASS_SET_TEACHER_CLASSES";
export const TEACHER_CLASS_GET_TEACHER_CLASSES =
  "TEACHER_CLASS_GET_TEACHER_CLASSES";
export const TEACHER_CLASS_SET_TEACHER_CLASS =
  "TEACHER_CLASS_SET_TEACHER_CLASS";

// TEACHER
export const TEACHER_SET_REQUEST = "TEACHER_SET_REQUEST";
export const TEACHER_SET_SUCCESS = "TEACHER_SET_SUCCESS";
export const TEACHER_SET_ERROR = "TEACHER_SET_ERROR";
export const TEACHER_SET_TEACHERS = "TEACHER_SET_TEACHERS";
export const TEACHER_GET_TEACHERS = "TEACHER_GET_TEACHERS";
export const TEACHER_SET_TEACHER = "TEACHER_SET_TEACHER";
export const TEACHER_GET_TEACHER = "TEACHER_GET_TEACHER";
export const TEACHER_UPDATE_TEACHER = "TEACHER_UPDATE_TEACHER";
export const TEACHER_ADD_TEACHER = "TEACHER_ADD_TEACHER";

// School
export const SCHOOL_SET_REQUEST = "SCHOOL_SET_REQUEST";
export const SCHOOL_SET_SUCCESS = "SCHOOL_SET_SUCCESS";
export const SCHOOL_SET_ERROR = "SCHOOL_SET_ERROR";
export const SCHOOL_SET_SCHOOLS = "SCHOOL_SET_SCHOOLS";
export const SCHOOL_GET_SCHOOLS = "SCHOOL_GET_SCHOOLS";
export const SCHOOL_SET_SCHOOL = "SCHOOL_SET_SCHOOL";
export const SCHOOL_GET_SCHOOL = "SCHOOL_GET_SCHOOL";
export const SCHOOL_ADD_SCHOOL = "SCHOOL_ADD_SCHOOL";
export const SCHOOL_DELETE_SCHOOL = "SCHOOL_DELETE_SCHOOL";
export const SCHOOL_UPDATE_SCHOOL = "SCHOOL_UPDATE_SCHOOL";
export const SCHOOL_SET_SCHOOL_BY_ID = "SCHOOL_SET_SCHOOL_BY_ID";
